var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"handle-user-bio-field"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'handle_user_bio',
        'tariffTags': _vm.getTagsByFieldKey('handle_user_bio'),
        'hasAnchor': true,
        'disabled': _vm.disabled
      }
    }}}),(_vm.$store.getters.isChatLicenseExists && _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand && this.handle_user_bio)?_c('a-alert',{attrs:{"type":"error","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('field_handle_user_bio_enabled_alert_message'))},slot:"message"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }